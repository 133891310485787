<template>

  <head>
    <link rel="stylesheet" href="./css/main.css">
  </head>

  <div class="common-layout">

    <el-container>
      <!-- 顶部菜单 -->
      <el-affix>
        <el-header>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <!-- 网站图标 -->
            <img src="//cdn.gusui.site/www/img/icon.png" alt="" srcset="" class="el-header-logo">
            <div class="flex-grow" />
            <!-- 菜单 -->
            <el-menu-item index="0" onclick="window.open ('//account.gusui.site/login/gusui')">用户中心</el-menu-item>
            <el-menu-item index="1" onclick="window.open ('//blog.gusui.site')">博客</el-menu-item>
            <el-menu-item index="2" onclick="window.open ('//blog.gusui.site/forums')">论坛</el-menu-item>
            <el-menu-item index="3" onclick="window.open ('//player.gusui.site')">播放器</el-menu-item>
            <el-menu-item index="4" onclick="window.open ('//doc.gusui.site')">文档站</el-menu-item>
            <el-menu-item index="5" onclick="window.open ('//download.gusui.site')">下载站</el-menu-item>
            <el-menu-item index="6" onclick="window.open ('//state.gusui.site')">网站监控</el-menu-item>
            <el-menu-item index="7" onclick="window.open ('//img.gusui.site')">图床</el-menu-item>
            <el-sub-menu index="8">
              <template #title>关于</template>
              <el-menu-item index="8-1" onclick="window.open ('//dsz.hello8693.xyz')"><el-space wrap>[生态]电视猪
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
                <el-menu-item index="8-2" onclick="window.open ('http://cdn.gusui.site/public/ToS.html')"><el-space wrap>用户条款
                  <TopRight style="width: 1em; height: 1em; margin-right: 8px;" />
                </el-space></el-menu-item>
            </el-sub-menu>
            <!-- <el-space wrap :size="30">
              <el-switch v-model="value1" size="large" :active-action-icon="Cloudy" :inactive-action-icon="Sunny" />
            </el-space>
            <div class="avatar"> 

              <el-avatar :size="50" :src="squareUrl" class="radius" />

            </div>-->
          </el-menu>

        </el-header>

      </el-affix>
      <br />

      <div class="video">
        <div class="name-parent">
          <div class="name">
            <!-- <h1>码上创新 开发无界</h1> 以梦为马不负韶华🎉-->
            <!-- <h1>&nbsp;未来，<br />&nbsp;&nbsp;&nbsp;码上发生</h1> -->
            <!-- <h1>谷穗社区</h1>
            <h1 class="describe">一个简洁的开发者社区</h1>
            </center> -->
            <img src="http://cdn.gusui.site/www/img/header.svg" alt="" />
          </div>
        </div>
        <img src="http://cdn.gusui.site/www/img/home-pc_1.webp.jpg" alt="" class="top" />
        <!-- <video src="./video/home_video.mp4" autoplay loop class="top"></video> -->
      </div>
      <!-- 主体 -->

      <el-main>
        <center class="containerbox">
          <!-- <div class="journeybox"> -->
          <br /><br /><br />
          <div id="journey" class="box ">
            <h1 style="font-size: 2.5rem;">你的梦想在此实现</h1><br><span class="heading-underline-1"></span>
            <br><br>
            <!-- <h3>左右滑动来查看</h3> -->

            <!-- </div> -->
            <center>
              <div class="containerbox">
                <div class="container" style="--t:20s">
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                </div>
                <div class="container" style="--t:50s">
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                </div>
                <div class="container" style="--t:30s">
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                </div>
                <div class="container" style="--t:40s">
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                  <div>
                    <span>HTML</span>
                    <span>JS</span>
                    <span>CSS</span>
                    <span>PHP</span>
                    <span>VUE</span>
                    <span>Element+</span>
                    <span>Python</span>
                    <span>博客</span>
                    <span>论坛</span>
                    <span>播放器</span>
                    <span>VS Code</span>
                    <span>PyCharm</span>
                  </div>
                </div>
              </div>
            </center>
          </div>


          <div class="blogbox">
            <div class="box"><br /><br /><br />
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">博客</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;谷穗社区博客功能，是一个汇聚创意与分享的平台。这里，您可以自由创作文章、分享图片与视频，展示您的独特见解和才华。
                  博客界面简洁，操作便捷，让您轻松发布内容。同时，您可以轻松将博客分享至各大社交平台，吸引更多关注。
                  在社区内，您可以浏览他人的博客，发现新鲜内容，并与他们互动交流，增进友谊，共享知识。
                  此外，个性化设置让您的博客独具魅力，吸引更多读者。
                </div><el-button type="primary" plain class="containerbutton" onclick="window.open ('//blog.gusui.site')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div><img class="pc" src="http://cdn.gusui.site/www/img/blog.png" style="">
              <br /><br /><br />
            </div>
          </div>
          <div class="discussbox">
            <div class="box"><br /><br /><br /><img class="pc" src="http://cdn.gusui.site/www/img/discuss.png" style="">
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">论坛</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;谷穗社区论坛功能，是一个汇聚智慧与热情的交流空间。在这里，您可以畅所欲言，发表观点，分享经验，与志同道合的伙伴共同探讨各种话题。论坛版块丰富多样，涵盖生活、科技、文化、娱乐等多个领域，满足您不同的兴趣需求。
                </div><br>

                <el-button class="containerbutton" onclick="window.open ('//blog.gusui.site/forums')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div>
              <br /><br /><br />
            </div>
          </div>
          <div class="blogbox">
            <div class="box"><br /><br /><br />
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">播放器</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;谷穗社区播放器，是一个专注视频播放与互动的轻量级工具。在这里，您可以将自己珍藏的各种格式视频播放，同时享受与社区成员互动发弹幕的乐趣。<br>
                  &emsp;&emsp;我们的播放器兼容多种视频格式，无论是常见的MP4、AVI，还是较为特殊的FLV、MKV，都能轻松播放，无需额外转换。您只需将视频文件上传至谷穗社区，便可立即在播放器中打开，享受流畅的播放体验。<br>
                  &emsp;&emsp;与此同时，谷穗社区播放器还提供了独特的弹幕功能。在观看视频的过程中，您可以随时发表自己的看法、感受，或是与其他观众进行实时互动。弹幕会以滚动形式出现在视频上方，让您在享受视频内容的同时，也能感受到社区的氛围和热情。<br>
                </div><el-button type="primary" plain class="containerbutton" onclick="window.open ('//doc.gusui.site/player文档')">文档<el-icon>
                    <Document />
                  </el-icon></el-button>
                <el-button type="primary" plain class="containerbutton" onclick="window.open ('//player.gusui.site')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div><img class="pc" src="http://cdn.gusui.site/www/img/player.png" style="">
              <br /><br /><br />
            </div>
          </div>
          <div class="discussbox">
            <div class="box"><br /><br /><br /><img class="pc" src="http://cdn.gusui.site/www/img/doc.png" style="">
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">文档站</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;文档站内的教程均由官方团队精心编写，确保内容准确、权威。我们深知用户在使用产品或服务时可能会遇到的困惑和问题，因此，我们提供的教程旨在为用户提供详细的操作步骤、清晰的解释以及实用的技巧，帮助用户更好地理解和使用相关产品。<br>

                  &emsp;&emsp;为了方便用户快速找到所需的教程，文档站采用了简洁明了的分类和标签系统。您可以根据产品名称、功能模块或关键词进行搜索，轻松定位到目标教程。同时，我们也提供了详细的教程列表和目录，方便用户浏览和选择。
                </div><br>
                <el-button class="containerbutton" onclick="window.open ('//doc.gusui.site')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div>
              <br /><br /><br />
            </div>
          </div>
          <div class="blogbox">
            <div class="box"><br /><br /><br />
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">用户中心</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;谷穗社区用户中心，采用单点登录技术，结合主流平台Casdoor，为您提供安全、便捷的登录体验。您可以轻松管理自己的个人信息、账户安全，以及享受谷穗社区提供的各项服务。<br>

                  &emsp;&emsp;单点登录技术意味着您只需在谷穗社区用户中心进行一次登录操作，即可无缝访问社区内的各个功能模块，无需频繁输入用户名和密码。这不仅提高了您的使用效率，还增强了账户的安全性。<br>
                  &emsp;&emsp;在谷穗社区用户中心，您可以方便地查看和编辑个人信息，包括头像、昵称、个人简介等。同时，我们还提供了账户安全设置选项，允许您绑定手机或邮箱等，进一步增强账户的安全性。<br>
                  <br>
                </div>
                <el-button type="primary" plain class="containerbutton" onclick="window.open ('//account.gusui.site/login/gusui')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div><img class="pc" src="http://cdn.gusui.site/www/img/account.png" style="">
              <br /><br /><br />
            </div>
          </div>
          <div class="discussbox">
            <div class="box"><br /><br /><br /><img class="pc" src="http://cdn.gusui.site/www/img/download.png" style="">
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">下载站</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;谷穗社区下载站，是社区成员们分享和获取各类资源的重要平台。在这里，您可以找到丰富多样的软件、工具、文档、音频、视频等资源，满足您在学习、工作和生活中的各种需求。<br>
                  &emsp;&emsp;为了方便用户快速找到所需的资源，下载站采用了清晰明了的分类和标签系统。您可以通过关键词搜索、分类浏览等方式，轻松定位到目标资源。同时，我们还提供了详细的资源介绍和预览功能，让您在下载前就能了解资源的大致内容和质量。<br>
                </div>
                <el-button class="containerbutton" onclick="window.open ('//download.gusui.site')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div>
              <br /><br /><br />
            </div>
          </div>
          <div class="blogbox">
            <div class="box"><br /><br /><br />
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">网站监控</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;谷穗社区网站监控，作为社区安全的重要守护者，致力于为用户提供实时、全面的网站安全监控服务。我们采用先进的监控技术和严格的安全策略，确保社区网站的安全稳定运行，让每一位用户都能享受到安全、畅快的社区体验。<br>

                  &emsp;&emsp;网站监控的核心功能在于实时检测网站的各项关键指标，包括服务器的运行状态、网站的访问速度、页面的加载情况等。一旦发现异常情况，我们的监控系统将立即发出警报，并自动启动应急响应机制，确保问题得到及时有效的处理。<br>
                </div>
                <el-button type="primary" plain class="containerbutton" onclick="window.open ('//state.gusui.site')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div><img class="pc" src="http://cdn.gusui.site/www/img/state.png" style="">
              <br /><br /><br />
            </div>
          </div>
          <div class="discussbox">
            <div class="box"><br /><br /><br /><img class="pc" src="http://cdn.gusui.site/www/img/img.png" style="">
              <div class="left" style="">
                <h1 style="font-size: 2.5rem;">图床</h1>
                <span class="heading-underline-1"></span>
                <br><br>
                <div class="sub">
                  &emsp;&emsp;谷穗社区图床，作为社区内图片存储与分享的重要平台，致力于为用户提供高效稳定、便捷易用的图片上传与分享服务。在这里，您可以轻松上传、管理和分享自己的图片，与社区成员共同分享美好瞬间。<br>

                  &emsp;&emsp;图床拥有强大的存储能力，支持高清大图上传，确保您的图片质量不受损失。同时，我们采用了先进的图片压缩技术，在保证图片质量的前提下，有效减小图片体积，节省您的存储空间。<br>

                  &emsp;&emsp;在上传图片方面，谷穗社区图床提供了简洁明了的操作界面和流畅的上传体验。您只需选择需要上传的图片，点击上传按钮，即可轻松将图片发布到图床中。同时，我们还支持批量上传功能，让您一次性上传多张图片，提高操作效率。<br>
                </div>
                <el-button class="containerbutton" onclick="window.open ('//img.gusui.site')">前往<el-icon>
                    <TopRight />
                  </el-icon></el-button>
              </div>
              <br /><br /><br />
            </div>
          </div>
        </center>
        <div class="journeybox"><br /><br /><br />
          <div class="box">
            <!-- <center> -->

            <!-- <br> -->
            <div id="journey">
              <h1 style="font-size: 2.5rem;">小站大事记</h1><span class="heading-underline-1"></span>
              <br><br>
              <h3>左右滑动来查看</h3>

              <div class="journey-container-wrapper">
                <span class="journey-mask left2"></span>
                <div id="journey-container">
                  <div>
                    <p class="journey-item-date">2020年8月11日</p>
                    <p class="journey-item-content">想做网站的想法在心中产生</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2020年11月</p>
                    <p class="journey-item-content">开始自学Html</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2021年1月</p>
                    <p class="journey-item-content">接触到更多编程语言，开始系统学习</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2022年1月</p>
                    <p class="journey-item-content">做了许多项目</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2022年5月</p>
                    <p class="journey-item-content">了解到了服务器和客户端的概念</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2022年8月</p>
                    <p class="journey-item-content">开始学习运维</p>
                    <p class="journey-item-content">用Python写了简单的页面</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年1月</p>
                    <p class="journey-item-content">购买了自己的服务器</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年1月</p>
                    <p class="journey-item-content">确定了网站名称为「谷穗」</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年1月</p>
                    <p class="journey-item-content">注册了「goohui.top」域名</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年1月</p>
                    <p class="journey-item-content">完成了ICP备案</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年8月</p>
                    <p class="journey-item-content">更换服务器厂商</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年8月</p>
                    <p class="journey-item-content">更换域名为「gusui.site」</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年8月</p>
                    <p class="journey-item-content">网站更名为「谷穗社区」</p>
                    <p class="journey-item-content">团队更名为「谷穗社区工作室」</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年8月13日</p>
                    <p class="journey-item-content">完成了ICP备案</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2023年8月</p>
                    <p class="journey-item-content">完成了公安部备案</p>
                  </div>
                  <div>
                    <p class="journey-item-date">2024</p>
                    <p class="journey-item-fade-out">未完待续...</p>
                  </div><span class="journey-mask right"></span>
                </div>
              </div>
            </div>

            <!-- </center> -->
          </div>
        </div>

        <!-- <> -->



        <!-- <section tg-name="scrolled" data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img
            data-v-68e706e5="" data-v-092338f0="" src="//myclass.gusui.site/images/index_home2.jpg" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png"
            class="ip"><img data-v-68e706e5="" data-v-092338f0=""
            src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png" class="ip"></section>

        <section data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img data-v-68e706e5=""
            data-v-092338f0="" src="//myclass.gusui.site/images/bg_max.png" class="ip"><img data-v-68e706e5=""
            data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png"
            class="ip"></section>
        <section data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img data-v-68e706e5=""
            data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-python.6e970c78.png" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png"
            class="ip"><img data-v-68e706e5="" data-v-092338f0=""
            src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png" class="ip"></section>
        <section data-v-68e706e5="" data-v-092338f0="" class="section section-ips"><img data-v-68e706e5=""
            data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-python.6e970c78.png" class="ip"><img
            data-v-68e706e5="" data-v-092338f0="" src="//ali.bczcdn.com/nocturne/static/img/ip-excel.8bd8607a.png"
            class="ip"><img data-v-68e706e5="" data-v-092338f0=""
            src="//ali.bczcdn.com/nocturne/static/img/ip-data-analysis.819cc42c.png" class="ip"></section> -->
      </el-main>
      <!-- Footer -->
      <!-- 底部 -->
      <!-- <el-footer>{{ info.Message }}</el-footer> -->
      <el-footer class="pc">
        <center>Copyright © 2020-2024 谷穗社区工作室&emsp;
          <a target="_blank" href="https://beian.miit.gov.cn">鲁ICP备2022032319号-3</a>&emsp;
          <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=37069302888890">鲁公网安备
            37069302888890号</a>
        </center>
      </el-footer>
      <el-footer class="m">
        <center>Copyright © 2020-2024 谷穗社区工作室<br>
          <a target="_blank" href="https://beian.miit.gov.cn">鲁ICP备2022032319号-3</a><br>
          <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=37069302888890">鲁公网安备
            37069302888890号</a>
        </center>
      </el-footer>
    </el-container>
  </div>

  <el-backtop :right="20" :bottom="20" />
</template>

<!-- <script src="https://cdn.staticfile.org/axios/0.18.0/axios.min.js"></script> -->
<!-- <script>
import { ElLoading } from 'element-plus'
const loadingInstance = ElLoading.service({ fullscreen: true })
document.onreadystatechange = function () {
  if (document.readyState == "complete") {
    loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
    // loadingInstance.close();
  }
}
</script > -->
<script>
import axios from 'axios'
setTimeout(() => {
  const boxes = document.querySelectorAll('.box');
  console.log(boxes);
  window.addEventListener('scroll', () => {
    const tb = window.innerHeight;
    boxes.forEach((item) => {
      const boxTop = item.getBoundingClientRect().top;
      if (boxTop < tb * 3 / 5) {
        item.classList.add('show');
      } else if (boxTop > tb) {
        item.classList.remove('show');
      }
    })
  })
}, 100);

export default {
  name: 'App',
  data: function () {
    return {
      // 
      info: '123',
      // info2:"123456"
    }
  },
  mounted() {
    axios
      .get('http://127.0.0.1:8099/v1/test')
      .then(response => (this.info = response.data))
      .catch(function (error) { // 请求失败处理
        console.log(error);
      });
  },
  methods: {

  }

}

</script>
<!-- <script src="//cdn.jsdelivr.net/npm/@element-plus/icons-vue"></script> -->
<!-- <script setup>
import { ref } from 'vue';
import { Cloudy, Sunny } from '@element-plus/icons-vue';
const value1 = ref(true);

</script> -->